import api from "@/api";
import Card from "@/components/Card";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import Switch from "@/components/Switch";
import { PaginationRow, SearchListRow, TableRow } from "@/models/type";
import { ElNotification } from "element-plus";
import { Vue, Watch } from "vue-property-decorator";
import OrdinaryDetail from "./detail";
import './index.scss'

export default class Ordinary extends Vue {

  public type = ''
  private title = ''
  public detailId = 0
  public switchNum = 0
  private tableData: Array<any> = []
  private tableList: Array<TableRow> = [
    { label: '序号', slot: {
      default: (scope: any) => <span>{scope.$index + 1}</span>
    } },
    { label: '用户编号', prop: 'memberId' },
    { label: '用户名', prop: 'nickname' },
    { label: '性别', slot: {
      default: (scope: any) => <span>{scope.row.gender === 1 ? '男' : '女'}</span>
    } },
    { label: '手机号', prop: 'phone' },
    { label: '邮箱', prop: 'email' },
    { label: '身份证', prop: 'cardId' },
    { label: '账户状态', slot: {
      default: (scope: any) => <Switch switchBool={ scope.row.status } bindValue={{ open: 1, close: 0 }} onSwitch={val => this.switchClick(val, scope.row.id)} />
    } },
    { label: '操作', slot: {
      default: (scope: any) => <div>
        <span class="span-button-primary" onClick={ () => this.clientDetail(scope.row.id) }>详情</span>
      </div>
    } },
  ]
  private searchList: Array<SearchListRow> = [
    { label: '用户编号', type: 'input', submitKey: 'memberId', placeholder: '请输入用户编号' },
    { label: '姓名', type: 'input', submitKey: 'name', placeholder: '请输入姓名' },
    { label: '身份证号', type: 'input', submitKey: 'cardId', placeholder: '请输入身份证号' },
    { label: '手机号', type: 'input', submitKey: 'phone', placeholder: '请输入手机号' },
    { label: '邮箱', type: 'input', submitKey: 'email', placeholder: '请输入邮箱' },
    { label: '创建时间', type: 'time', submitKey: ['startTime', 'endTime']},
  ]
  public searchObj: any = {}
  private total = 0

  switchClick(status, ids): void {
    api.teamSetMemberStatus({
      status
    }, [ids]).then(() => {
      ElNotification({
        type: 'success',
        message: '操作成功'
      })
      this.switchNum++
    })
  }

  clientDetail(id: number): void {
    this.detailId = id
    this.type = 'detail'
  }

  @Watch('switchNum')
  switchNumChange(num: number): void {
    if(num){
      this.getList()
    }
  }

  onSearch(row: never): void {
    this.searchObj = Object.assign({}, row)
    this.getList()
  }

  tableDom(): JSX.Element {
    return <el-table
      data={ this.tableData }
    >
      {this.tableList.map(item => (
        <el-table-column
          label={ item.label }
          align="center"
          prop={ item.prop }
          v-slots={ item.slot }
        />
      ))}
    </el-table>
  }
  getList(): void {
    this.tableData = []

    const __pagination = this.$refs.pagination as any
    const { pageNum, pageSize } = __pagination.paginationObj as PaginationRow
    api.teamGetMemberList({pageNum, pageSize, kind: 2, ...this.searchObj}).then(res => {
      this.tableData = res.data.list
      this.total = res.data.total
    })
  }

  render(): JSX.Element {
    return this.type
      ? <OrdinaryDetail />
      : <div class="ordinary">
          <Card title="账户查询" class="ordinary-card">
            <Search class="ordinary-search" list={ this.searchList } onSearch={ this.onSearch } />
          </Card>
          <Card title="账户信息" >
            {this.tableDom()}
            <Pagination ref="pagination" total={ this.total } onSizeChange={ this.getList } onCurrentChange={ this.getList } />
          </Card>
        </div>
  }
  mounted(): void {
    this.getList()
  }
}
